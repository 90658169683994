:root {
    --texto-geral: #9D9AB2;
    --texto-destaque: #707070;
    --texto-sessao-principal: #F28981;
    --texto-menu: #595769;
    --btn-sem-fundo-borda: #20C3C5;
    --btn-sem-fundo-texto: #20C3C5;
    --btn-preenchido-fundo: var(--cor-principal);
    --btn-preenchido-texto: ffffff;
    --section-background-gradient: linear-gradient(130deg, rgba(251, 248, 255, 1) 0%, rgba(241, 232, 252, 1) 100%);
    --logo-gradiente: linear-gradient(90deg, rgba(206, 114, 114, 1) 0%, rgba(65, 11, 108, 1) 100%);
    --section-titulo-cor: #34314B;
    --section-titulo-font-size: 50px;
    --section-titulo-line-height: 59px;
    --cor-principal: #8116D7;
    --cor-secundaria: #F28981;
    --icone-gradiente: -webkit-linear-gradient(#0bb1d3, #3090de, #9f2fff);
    --icone-funcionalidades: -webkit-linear-gradient(#E93E3A, #ED683C, #F3903F, #FDC70C);
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: ralewayRegular;
    src: url(fonts/Raleway-Regular.ttf);
}

@font-face {
    font-family: ralewayBold;
    src: url(fonts/Raleway-Bold.ttf);
}

@font-face {
    font-family: ralewaySemiBold;
    src: url(fonts/Raleway-SemiBold.ttf);
}

@font-face {
    font-family: ralewayItalic;
    src: url(fonts/Raleway-Italic.ttf);
}

body {
    margin: 0;
    font-family: ralewayRegular!important;
}

strong {
    font-family: ralewayBold!important;
}