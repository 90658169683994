@font-face {
  font-family: 'like-icons';
  src:  url('fonts/like-icons.eot?944ijv');
  src:  url('fonts/like-icons.eot?944ijv#iefix') format('embedded-opentype'),
  url('fonts/like-icons.ttf?944ijv') format('truetype'),
  url('fonts/like-icons.woff?944ijv') format('woff'),
  url('fonts/like-icons.svg?944ijv#like-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="like-icon-"], [class*=" like-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'like-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.like-icon-ifood:before {
  content: "\e900";
}
.like-icon-mapa-de-ruas:before {
  content: "\e901";
}
.like-icon-catracas:before {
  content: "\e902";
}
.like-icon-menu-toggle:before {
  content: "\e94a";
}
.like-icon-pedir-no-app:before {
  content: "\e94b";
}
.like-icon-user-checked:before {
  content: "\e94c";
}
.like-icon-nota-fiscal:before {
  content: "\e91b";
}
.like-icon-balanca:before {
  content: "\e949";
}
.like-icon-cadastro-lanche:before {
  content: "\e91c";
}
.like-icon-cardapio-online:before {
  content: "\e928";
}
.like-icon-cardapio-digital:before {
  content: "\e948";
}
.like-icon-calendar:before {
  content: "\e947";
}
.like-icon-garcom-online:before {
  content: "\e90e";
}
.like-icon-atendimento:before {
  content: "\e91a";
}
.like-icon-cadastro:before {
  content: "\e91d";
}
.like-icon-cadastro-limpo:before {
  content: "\e91e";
}
.like-icon-computador-portatil:before {
  content: "\e91f";
}
.like-icon-centro-custo:before {
  content: "\e920";
}
.like-icon-relatorios:before {
  content: "\e921";
}
.like-icon-dashboard:before {
  content: "\e922";
}
.like-icon-assinatura-digital:before {
  content: "\e923";
}
.like-icon-contas-pessoa:before {
  content: "\e924";
}
.like-icon-controle-impressao:before {
  content: "\e925";
}
.like-icon-monitor-tempo:before {
  content: "\e926";
}
.like-icon-taxa-entrega:before {
  content: "\e927";
}
.like-icon-experiencia-usuario:before {
  content: "\e929";
}
.like-icon-relatorios-online:before {
  content: "\e92a";
}
.like-icon-divisao-contas:before {
  content: "\e92b";
}
.like-icon-integracao-dispositivos:before {
  content: "\e92c";
}
.like-icon-grupo-pessoas:before {
  content: "\e92d";
}
.like-icon-cupcake:before {
  content: "\e92e";
}
.like-icon-bufe:before {
  content: "\e92f";
}
.like-icon-cardapio:before {
  content: "\e930";
}
.like-icon-carne:before {
  content: "\e931";
}
.like-icon-culinaria-japonesa:before {
  content: "\e932";
}
.like-icon-sacola-compras:before {
  content: "\e933";
}
.like-icon-sorvete:before {
  content: "\e934";
}
.like-icon-xicara-cafe:before {
  content: "\e935";
}
.like-icon-lista-de-controle:before {
  content: "\e936";
}
.like-icon-carrinho-mercado:before {
  content: "\e937";
}
.like-icon-motocicleta:before {
  content: "\e938";
}
.like-icon-nuvem:before {
  content: "\e939";
}
.like-icon-ordem:before {
  content: "\e93a";
}
.like-icon-app-garcom:before {
  content: "\e93b";
}
.like-icon-perfil:before {
  content: "\e93c";
}
.like-icon-caixa:before {
  content: "\e93d";
}
.like-icon-presente:before {
  content: "\e93e";
}
.like-icon-receitas:before {
  content: "\e93f";
}
.like-icon-recibo:before {
  content: "\e940";
}
.like-icon-reembolso:before {
  content: "\e941";
}
.like-icon-rota:before {
  content: "\e942";
}
.like-icon-seguranca-dados:before {
  content: "\e943";
}
.like-icon-conectividade:before {
  content: "\e944";
}
.like-icon-varios-telefones:before {
  content: "\e945";
}
.like-icon-leitura-qrcode:before {
  content: "\e946";
}
.like-icon-chevron-right-solid:before {
  content: "\e904";
}
.like-icon-chevron-left-solid:before {
  content: "\e94d";
}
.like-icon-chevron-down-solid:before {
  content: "\e94e";
}
.like-icon-chevron-up-solid:before {
  content: "\e94f";
}
.like-icon-logo-ei-chefe:before {
  content: "\e905";
}
.like-icon-logo-qsp:before {
  content: "\e906";
}
.like-icon-backup:before {
  content: "\e907";
}
.like-icon-bell:before {
  content: "\e908";
}
.like-icon-beer:before {
  content: "\e909";
}
.like-icon-cloud:before {
  content: "\e90a";
}
.like-icon-coment:before {
  content: "\e90b";
}
.like-icon-food:before {
  content: "\e90c";
}
.like-icon-confirm:before {
  content: "\e90d";
}
.like-icon-delivery:before {
  content: "\e90f";
}
.like-icon-facebook:before {
  content: "\e910";
}
.like-icon-instagram:before {
  content: "\e911";
}
.like-icon-logo-chefe:before {
  content: "\e912";
}
.like-icon-logo-like:before {
  content: "\e913";
}
.like-icon-logo-service:before {
  content: "\e914";
}
.like-icon-mail:before {
  content: "\e915";
}
.like-icon-padaria:before {
  content: "\e916";
}
.like-icon-pizza:before {
  content: "\e917";
}
.like-icon-place:before {
  content: "\e918";
}
.like-icon-responsive:before {
  content: "\e919";
}
.like-icon-scooter:before {
  content: "\e950";
}
.like-icon-smartphone:before {
  content: "\e951";
}
.like-icon-twitter:before {
  content: "\e952";
}
.like-icon-whatsapp:before {
  content: "\e953";
}
